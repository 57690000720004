<template>
     <v-row class="fill-height mt-16">
        <v-col cols="12" align="center">
            <span class="text-h3 font-weight-light grey--text text--darken-2">
                Kiosk Analytics Coming Soon!
            </span>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'kioskAnalytics'
}
</script>